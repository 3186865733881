@import "variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");
@import "~react-image-gallery/styles/scss/image-gallery.scss";

body {
  background-color: $main !important;
  font-family: "Roboto Condensed", sans-serif !important;
  font-weight: 500;
  color: $text;
}

.top-bar-web-wrap {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.top-bar-web {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  background-color: $main;
  padding: 0 20px;

  .top-bar-web__vehicle-selected {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 220px;

    span {
      color: $accent;
      font-size: 14px;
      margin-left: 3px;
      position: relative;
      top: 3px;
      font-weight: bold;
    }

    img {
      width: 30px;
    }
  }

  .top-bar-web__search {
    display: flex;
    align-items: center;
    position: relative;

    svg {
      margin-right: 10px;
    }
    input {
      background-color: transparent;
      height: 40px;
      border: none;
      color: $text;
      width: 250px;
      padding-left: 20px;
      font-weight: 300;
    }
    input::placeholder {
      color: $textSecondary;
    }
    input:focus {
      border: none;
      outline: none;
    }

    .top-bar-web__search__result {
      position: absolute;
      top: 40px;
      left: -90px;
      width: 400px;
      height: auto;
      min-height: 100px;
      background-color: $main;
      border: 1px solid lighten($main, 15);

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      .top-bar-web__search__result__empty {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100px;
      }

      .top-bar-web__search__result__item:hover {
        background-color: darken($main, 5);
      }

      .top-bar-web__search__result__item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
        transition: all 0.25s ease;

        .top-bar-web__search__result__item__img {
          width: 50px;
          height: 50px;
          background-size: cover !important;
          background-position: center !important;
          flex-shrink: 0;
          margin-left: 10px;
        }

        .top-bar-web__search__result__item__info {
          color: $textSecondary;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding-left: 10px;
          padding-right: 10px;

          .top-bar-web__search__result__item__info__price {
            width: 100px;
            flex-shrink: 0;
            display: none;
          }
        }
      }
    }
  }

  .top-bar-web__user {
    display: flex;
    align-items: center;
    cursor: pointer;

    .top-bar-web__user__name {
      color: $textSecondary;
      margin-left: 10px;
      font-weight: 400;
    }
  }

  .top-bar-web__message {
    color: $textSecondary;
  }
}

.hero-section {
  width: 100%;
  height: 60vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover !important;
  background-position: center !important;
  margin-top: 100px;

  .hero-section__caption {
    color: $text;
    h4 {
      font-size: 36px;
      margin: 0;
    }
    h1 {
      font-size: 60px;
      font-weight: bold;
    }

    @media (max-width: 600px) {
      h1 {
        font-size: 26px;
        font-weight: bold;
      }
      h4 {
        font-size: 24px;
        margin: 0;
      }
    }
  }
}

.hero-section-page {
  width: 100%;
  height: 40vh;
  margin-bottom: 40px;
  background-color: black;
  background-size: cover !important;
  background-position: center !important;
  margin-top: 100px;

  .hero-section-page-tint {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hero-section-page__caption {
    color: $text;
    h4 {
      font-size: 36px;
      margin: 0;
    }
    h1 {
      font-size: 60px;
      font-weight: bold;
    }

    @media (max-width: 600px) {
      h4 {
        font-size: 21px;
        margin: 0;
      }
      h1 {
        font-size: 32px;
        font-weight: bold;
        line-height: 0.75;
      }
    }
  }
}

.top-bar-web__menu {
  width: 100%;
  height: 60px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: darken($main, 5);

  .top-bar-web__menu__logo {
    cursor: pointer;
    img {
      width: 110px;
    }
  }
  .top-bar-web__menu__nav {
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;

      li {
        padding: 0 20px;
        cursor: pointer;
        color: $text;
        font-weight: 400;
      }
    }
  }

  .top-bar-web__menu__cart {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    .top-bar-web__menu__cart__total {
      margin-left: 10px;

      span {
        color: $text;
      }
    }

    .top-bar-web__menu__cart__icon {
      .total-items {
        position: absolute;
        left: -20px;
        top: -10px;
        margin: 0;
        background-color: $secondary;
        width: 20px;
        height: 20px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: $text;
      }
    }
  }
}

.section-wrap {
  padding: 20px 0;
  margin-bottom: 20px;
}

.section-title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $text;
  h2 {
    font-weight: bold;
    font-size: 43px;
  }
  h2,
  h5 {
    margin: 0;
    padding: 0;
  }

  .section-title__actions {
    color: $secondary;
    font-weight: bold;
    cursor: pointer;
  }
}

.product-row:hover {
  .product-row__info {
    background-color: darken($main, 20);
  }

  .product-row__info__name {
    color: $accent;
  }
}

.product-row {
  @media (max-width: 600px) {
    width: 100%;
  }

  @media only screen and (min-width: 601px) and (max-width: 980px) {
    width: 49%;
  }
  width: 32.3333333333%;

  margin-right: 1%;
  margin-bottom: 20px;
  border-radius: 20px;
  .product-row__image {
    cursor: pointer;
    width: 100%;
    height: 300px;
    background-size: cover !important;
    background-position: center !important;
  }
  .product-row__info {
    width: 100%;
    height: 150px;
    background-color: darken($main, 3);
    padding: 20px;
    color: $text;
    transition: all 0.25s ease;

    .product-row__info__name {
      font-weight: bold;
      cursor: pointer;
      font-size: 18px;
      transition: all 0.25s ease;
    }
    .product-row__info__price {
      display: flex;
      align-items: center;

      .product-row__info__price__regular--whithOffer {
        margin-right: 10px;
        color: $textSecondary;
        text-decoration: line-through;
      }

      .product-row__info__price__regular {
        font-weight: bold;
        font-size: 19px;
        color: $textSecondary;
      }
      .product-row__info__price__sale {
        font-weight: bold;
        font-size: 19px;
        color: $textSecondary;
      }
    }
  }

  .product-row__actions {
    margin-top: 10px;
  }
}

.grid-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.dark-section {
  background-color: darken($main, 4);
}

.categories-banner {
  border-radius: 20px;
  .categories-tint {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 75px 20px;
    border-radius: 20px;
  }

  .categories-banner__vehicle-image {
    img {
      width: 100%;
    }
  }
  .categories-banner__list {
    ul {
      color: $text;
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      li {
        cursor: pointer;
        text-transform: uppercase;
        font-weight: bold;
        padding-right: 20px;
        font-size: 24px;
        transition: all 0.25s;
      }
      li:hover {
        color: $secondary;
      }
    }
  }
}

.post-row:hover {
  .post-row__tint {
    background-color: rgba(40, 218, 173, 0.55);
  }
}

.post-row {
  height: 200px;
  width: 200px;
  @media (max-width: 600px) {
    width: 95%;
  }

  margin-right: 20px;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 20px;
  margin-bottom: 20px;
  cursor: pointer;

  .post-row__tint {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55);
    border-radius: 20px;
    padding: 10px;
    display: flex;
    align-items: flex-end;
    transition: all 0.25s ease;
  }

  .post-row__info-wrap {
    display: flex;
    align-items: center;

    .post-row__info-wrap__avatar {
      flex-shrink: 0;
    }

    .post-row-wrap__info {
      color: $text;
      margin-left: 10px;

      .post-row-wrap__info__user {
        font-weight: bold;
        line-height: 0.75;
      }
      span {
        display: block;
      }
    }
  }
}

.footer {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: darken($main, 5);
  padding: 0 20px;

  a {
    color: $accent;

    font-weight: bold;
  }
}

.sidebar {
  background-color: darken($main, 4);
  border-radius: 20px;
  padding: 20px;

  .sidebar__title {
    font-size: 21px;
    font-weight: bold;
    color: $text;
  }
  .sidebar__divider {
    height: 1px;
    background-color: lighten($main, 5);
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .sidebar__list {
    list-style: none;
    margin: 0;
    padding: 0;
    color: $text;
    font-size: 16px;
    font-weight: 500;
    li {
      padding: 2px 0;
      transition: all 0.25s;

      cursor: pointer;
    }
    li:hover {
      color: $secondary;
    }
  }
}

.vehicle-selected {
  .vehicle-selected__image {
    width: 100%;
  }
  .vehicle-selected__info__brand {
    color: $textSecondary;
    font-size: 19px;
    line-height: 0.75;
  }
  .vehicle-selected__info__change {
    color: $secondary;
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
    cursor: pointer;
  }
}

.product__info {
  color: $text;
  .product__info__caption {
    color: $text;
    font-weight: bold;
    font-size: 14px;
    display: block;
  }

  .product__info__name {
    span {
      font-size: 38px;
      font-weight: bold;
    }
  }
  .product__info__price {
    .product__info__price__number {
      color: $text;
      font-size: 28px;
      font-weight: bold;
      display: block;
    }
  }

  .product__info__category {
    .product__info__category__text {
      font-weight: bold;
      color: $secondary;
    }
  }

  .product__info__addToCart {
    margin-top: 20px;
  }
}

.tabs__wrap {
  margin-top: 50px;

  .tabs__header {
    margin-bottom: 0px;
  }
}

.tab__content {
  background-color: darken($main, 5);
  padding: 20px;
  font-size: 18px;
  color: $textSecondary;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  h2 {
    margin-bottom: 10px;
  }
}

.vehicle {
  width: 24%;
  cursor: pointer;
  transition: all 0.5s;
  background-color: darken($main, 3);
  margin-left: 0.5%;
  margin-right: 0.5%;

  @media (max-width: 600px) {
    width: 100%;
  }

  @media only screen and (min-width: 601px) and (max-width: 980px) {
    width: 50%;
  }

  img {
    width: 100%;
  }

  .vehicle__info {
    background-color: darken($main, 3);
    padding: 10px;
    color: $text;
    transition: all 0.5s;
  }
  .vehicle__remove {
    padding: 5px 10px;
    color: $text;
    transition: all 0.5s;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: red;
    cursor: pointer;
    font-size: 20px;
  }
  .vehicle__info__name {
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.5s;
  }

  .vehicle__info__brand {
    color: $textSecondary;

    h6 {
      color: $textSecondary;
    }
  }
  .vehicle__img {
    background-color: darken($main, 2);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 10px;
    transition: all 0.5s;
  }
}
.vehicle:hover {
  background-color: darken($main, 5);
  .vehicle__info__name {
    color: $secondary;
  }
  .vehicle__info {
    background-color: darken($main, 5);
  }
  .vehicle__img {
    background-color: darken($main, 8) !important;
  }
}

.vehicleFull {
  width: 100% !important;
}

.cart {
  min-height: 75vh;

  .cart__table {
    background-color: darken($main, 3);
    color: $text;
    border-radius: 0px;

    .cart__total {
      background-color: $main;
      display: flex;
      justify-content: flex-end;
      padding-top: 20px;
    }

    .cart__table__item__hasImage {
      display: flex;
      align-items: center;

      img {
        width: 50px;
        border-radius: 8px;
        margin-right: 10px;
      }
      span {
        font-weight: bold;
      }
    }

    .table {
      color: $text !important;
    }

    table th,
    .table td {
      border-top: 0px !important;
    }
  }
}

.check-out {
  background-color: darken($main, 4);
  padding: 20px;
  border-radius: 20px;

  .form-label {
    color: $text;
  }
}

.check-out__recap {
  background-color: $white;
  padding: 20px;
  border-radius: 20px;
  color: $textSecondary;

  .check-out__items {
    margin-top: 20px;
    .check-out__item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .check-out__item__info {
        display: flex;
        align-items: center;
      }
    }
  }

  .check-out__subTotal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid $textSecondary;
    margin-bottom: 10px;
    margin-top: 10px;

    .check-out__subTotal__caption {
      font-weight: bold;
    }
    .check-out__subTotal__price {
      font-weight: bold;
    }
  }

  .check-out__shipping {
    border-bottom: 1px solid $textSecondary;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding: 10px 0;
    transition: all 0.5;

    .check-out__shipping__item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      padding: 5px 0;
      transition: all 0.5s;

      img {
        width: 50px;
      }
    }

    .check-out__shipping__item:hover {
      background-color: #ddd;
    }
  }

  .check-out__total {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .check-out__total__price {
      font-weight: bold;
      font-size: 24px;
    }
  }

  .check-out__action {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}

.account {
  min-height: 75vh;
}

.login__login {
  background-color: darken($main, 3);
  padding: 20px;
  margin-top: 20px;
  padding-bottom: 30px;

  h4 {
    color: $text;
  }

  label {
    color: $text;
  }
}

.form-box {
  padding: 20px;
  margin-top: 20px;
  padding-bottom: 30px;

  h4 {
    color: $text;
  }

  label {
    color: $text;
  }
}

.form-box--darken {
  background-color: darken($main, 3);
}

.form-box--lighten {
  background-color: lighten($main, 3);
}

.form-box--transparent {
  background-color: darken($main, 20);
  padding: 20px !important;
  border-radius: 20px;
  padding: 0;
}

.divider-light {
  border: 1px solid white;
}

.login__register__action {
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    color: $text;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.text {
  color: $text;
}

.category-row {
  width: 48%;
  height: 250px;
  margin-right: 2%;
  background-size: cover !important;
  background-position: center !important;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    width: 100%;
  }

  @media only screen and (min-width: 601px) and (max-width: 980px) {
    width: 49%;
  }

  .category-row__content {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    padding: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    transition: all 0.5s;

    h3 {
      margin: 0;
      padding: 0;
      color: white;
      font-weight: bold;
      font-size: 36px;
      text-transform: uppercase;
      display: block;
    }
  }
}
.category-row:hover {
  .category-row__content {
    background-color: rgba(0, 0, 0, 0);
  }
}

@media (max-width: 980px) {
  .rs-drawer-right.rs-drawer-sm {
    width: 100% !important;
  }
}

// * MENU
#primary_nav_wrap {
  margin-top: 15px;
}

#primary_nav_wrap ul {
  list-style: none;
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
  max-height: 75vh;
}

#primary_nav_wrap ul a {
  display: block;
  color: #333;
  text-decoration: none;
  font-weight: 700;
  font-size: 12px;
  line-height: 32px;
  padding: 0 15px;
  font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#primary_nav_wrap ul li {
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
}

#primary_nav_wrap ul li.current-menu-item {
  background: #ddd;
}

#primary_nav_wrap ul li:hover {
  background: #f6f6f6;
}

#primary_nav_wrap ul ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  padding: 0;
}

#primary_nav_wrap ul ul li {
  float: none;
  width: 200px;
}

#primary_nav_wrap ul ul a {
  line-height: 120%;
  padding: 10px 15px;
}

#primary_nav_wrap ul ul ul {
  top: 0;
  left: 100%;
}

#primary_nav_wrap ul li:hover > ul {
  display: block;
}

.vehicle-selcted-fixed {
  width: 150px;
  height: 50px;
  background-color: $accent;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.rs-drawer-content {
  background-color: darken($main, 3) !important;
  color: $text !important;
}

.drawer__list-vehicles {
  .drawer__list-vehicles__item {
    cursor: pointer;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    border: 1px solid lighten($main, 3);
    padding: 10px;

    .drawer__list-vehicles__item__name {
      font-weight: bold;
    }

    .drawer__list-vehicles__item__info {
      span {
        margin-right: 5px;
      }
    }

    img {
      width: 50px;
      margin-right: 20px;
    }
  }

  .drawer__list-vehicles__item:hover {
    background-color: lighten($main, 8);
  }

  .drawer__list-vehicles__item--active {
    cursor: pointer;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    border: 1px solid lighten($main, 3);
    padding: 10px;
    background-color: $secondary !important;
    .drawer__list-vehicles__item__name {
      font-weight: bold;
    }

    .drawer__list-vehicles__item__info {
      span {
        margin-right: 5px;
      }
    }

    img {
      width: 50px;
      margin-right: 20px;
    }
  }
}

.rs-btn-primary.rs-btn-orange {
  background-color: $secondary !important;
  font-weight: bold;
  border-radius: 0 !important;
  font-weight: bold !important;
  font-size: 21px;
}

.rs-btn-ghost.rs-btn-orange {
  color: $secondary !important;
  border: 1px solid $secondary !important;
  background-color: transparent;
  font-weight: bold;
  border-radius: 0 !important;
  font-size: 21px;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 60px;
  width: 30px;
}

.empty__cart {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 250px;
  text-align: center;

  span {
    text-align: center;
    font-size: 24px;
    padding-left: 20px;
  }
}

.form__item {
  margin-bottom: 10px;
  .form__item__label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    color: $text;
  }
}

.post-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid lighten($main, 10);
  padding-bottom: 10px;

  .post-list-item__left {
    display: flex;
    align-items: center;

    img {
      width: 75px;
      margin-right: 10px;
    }

    .post-list-item__left__vehiculo {
      font-weight: bold;
      margin: 0;
      padding: 0;
    }
  }
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  flex-direction: column;
}

.drawer-post {
  img {
    width: 100%;
  }

  .drawer-post__user {
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    .drawer-post__user__name {
      display: block;
      margin-left: 5px;
      font-size: 19px;
      font-weight: bold;
    }
  }

  .drawer-post__caption {
    margin-bottom: 20px;
    p {
      font-size: 21px;
    }

    .drawer-post__caption__vehiculo {
      font-size: 16px;
      color: $accent;
      margin-top: 0;
      padding-top: 0;
    }
  }
}

.breadcrumb {
  background-color: transparent !important;
}

.bread-item {
  color: "white" !important;
}

.form-label {
  font-weight: bold;
}

.table thead th {
  border: none !important;
}

.venta {
  background-color: darken($main, 10);
  padding: 20px;
  border-radius: 20px;
  .venta__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .venta__totals {
    margin-top: 40px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    .venta__totals__item {
      display: flex;
      align-items: center;

      .venta__totals__item__text {
        margin-left: 10px;
        font-weight: bold;
        font-size: 21px;
      }

      .venta__totals__item__grand-total {
        font-size: 36px;
      }
    }
  }
}

.contact {
  p {
    font-size: 18px;
  }
}

.fade:not(.show) {
  opacity: 1 !important;
}

.rs-drawer-backdrop .fade:not(.show) {
  opacity: 0.5 !important;
}

.menu-mobile {
  width: 100%;
  display: flex;
  justify-content: space-between;

  height: 50px;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;

  .menu-mobile__toggle {
    height: 50px;
    display: flex;
    width: 50px;
    align-items: center;
    font-size: 28px;
  }

  .menu-mobile__search {
    height: 50px;
    display: flex;
    align-items: center;
    width: 40px;
    justify-content: flex-end;
    font-size: 24px;
  }
}

.menu-mobile__logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  img {
    width: 100px;
  }
}

.menu-mobile__vehiculo {
  background-color: darken($main, 5);
  display: flex;
  align-items: center;
  height: 40px;
  .menu-mobile__vehiculo__selected__false,
  .menu-mobile__vehiculo__selected__false {
    width: 100%;
    display: flex;
    align-items: center;

    img {
      width: 40px;
    }
  }
}

@media (max-width: 980px) {
  .rs-drawer-right.rs-drawer-sm {
    width: 100% !important;
  }
}

.main-layout {
  min-height: 100vh;
}

.top-bar-responsive {
  display: none;

  @media (max-width: 900px) {
    display: block;
  }
}

.top-bar-web__menu__cart--res {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  .top-bar-web__menu__cart__total {
    margin-left: 10px;

    span {
      color: $text;
    }
  }

  .top-bar-web__menu__cart__icon {
    .total-items {
      position: absolute;
      left: -20px;
      top: -10px;
      margin: 0;
      background-color: $secondary;
      width: 20px;
      height: 20px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: $text;
    }
  }
}

.top-bar-desk {
  display: block;

  @media (max-width: 900px) {
    display: none;
  }
}

.top-bar-web__search--res {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 10px;

  svg {
    margin-right: 10px;
  }
  input {
    background-color: transparent;
    height: 40px;
    border: none;
    color: $text;
    width: 250px;
    padding-left: 20px;
    font-weight: 300;
  }
  input::placeholder {
    color: $textSecondary;
  }
  input:focus {
    border: none;
    outline: none;
  }

  .top-bar-web__search__result {
    position: absolute;
    top: 40px;
    left: -90px;
    width: 400px;
    height: auto;
    min-height: 100px;
    background-color: $main;
    border: 1px solid lighten($main, 15);

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .top-bar-web__search__result__empty {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100px;
    }

    .top-bar-web__search__result__item:hover {
      background-color: darken($main, 5);
    }

    .top-bar-web__search__result__item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ccc;
      padding-top: 5px;
      padding-bottom: 5px;
      cursor: pointer;
      transition: all 0.25s ease;

      .top-bar-web__search__result__item__img {
        width: 50px;
        height: 50px;
        background-size: cover !important;
        background-position: center !important;
        flex-shrink: 0;
        margin-left: 10px;
      }

      .top-bar-web__search__result__item__info {
        color: $textSecondary;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;

        .top-bar-web__search__result__item__info__price {
          width: 100px;
          flex-shrink: 0;
          display: none;
        }
      }
    }
  }
}

.main-layout {
  @media (max-width: 900px) {
    margin-top: 0 !important;
  }
}

.owl-prev,
.owl-next {
  font-size: 40px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.topFooter {
  width: 100%;
  height: auto;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  background-color: darken($main, 2);

  .topFooter-main {
    display: flex;
    justify-content: center;
    flex-direction: column;

    width: 100%;
    .topFooter-main__logo {
      margin-bottom: 20px;
      img {
        width: 100px;
      }
    }
  }

  .topFooter-main__social {
    width: 100%;
    border-top: 2px solid white;
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
